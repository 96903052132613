body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 100;
  font-style: normal;
}
.title-font {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
}
.font {
  font-family: "Roboto", sans-serif !important;
}
b {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.MuiToolbar-root {
  background-color: white !important;
}

.report-box-container {
}
.report-box-item {
  height: 180px;
  border: solid 1px #eeecec;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid-item {
  justify-content: center;
  align-items: center;
}
.chart-box-item {
  border: solid 1px #eeecec;
  padding-bottom: 0px !important;
}
.chart-box-title {
  margin: 15px !important;
  color: #555;
}
.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.report-box-item-value {
  color: #555 !important;
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-right: 5px !important;
}
.test-agent-button {
  /* text-transform: none !important; */
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #7b9928 !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #aac955 !important;
}
.css-1oqqzyl-MuiContainer-root {
  max-width: 1400px !important;
}
.call-tab-label {
  text-transform: none !important;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ff9a17 !important;
}
.css-11yukd5-MuiTabs-indicator {
  background-color: #ff9a17 !important;
}
.menu-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
  padding: 10px !important;
}
.nav-link {
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
  color: #555;
}
.action-title {
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  color: #555;
}

.transcript-item:hover {
  background-color: #eee !important;
  border-radius: 15px;
}
.transcript-item:hover span {
  font-weight: 500;
}
